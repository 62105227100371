import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";

const query = graphql`
  query Books {
    books: allMarkdownRemark(
      sort: { fields: frontmatter___highlight, order: ASC }
      filter: { fileAbsolutePath: { regex: "//content/books//" } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            description
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Books = () => (
  <StaticQuery
    query={query}
    render={({ books: { edges } }) => (
      <section id="features">
        <header>
          <h2>
            <Link to="/books">
              Works by <strong>Malissa</strong>
            </Link>
          </h2>
        </header>
        <div className="row aln-center">
          {edges.map(
            ({
              node: {
                frontmatter: {
                  title,
                  path,
                  description,
                  image: {
                    childImageSharp: { fluid }
                  }
                }
              }
            }) => (
              <div className="col-4 col-6-medium col-12-small" key={path}>
                <section>
                  <Link to={path} className="image featured">
                    <Img fluid={fluid} />
                  </Link>
                  <header>
                    <h3>
                      <Link to={path}>{title}</Link>
                    </h3>
                  </header>
                  <p>{description}</p>
                </section>
              </div>
            )
          )}
        </div>
      </section>
    )}
  />
);

export default Books;
