import React from "react";

import Layout from "../components/layout";
import Books from '../components/books';

const BooksPage = () => {
  return (
    <Layout>
      <Books />
    </Layout>
  );
};

export default BooksPage;
